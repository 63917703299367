import { EventEmitter } from "events";

export default class UploadManager extends EventEmitter {
    #wss = null;
    #loading = false;
    #rowData;
    #requestId;
    constructor(wss) {
        super();
        this.#rowData = [];
        this.#wss = wss;
        this.#requestId = null;
        this.currentPage = 1;
        this.setMaxListeners(10);
    }

    get IsLoading() {
        return this.#loading;
    }

    setLoading(flag) {
        this.#loading(flag);
    }

    get RowData() {
        return this.#rowData;
    }

    createRowTable(rowData) {
        this.#rowData = rowData;
        return this.#rowData;
    }

    listen() {
        this.#wss.subscribe("upload", (data) => {
            const message = data.message;
            switch (message.status) {
                case "progress":
                    this.createRowTable(message.rowdata);
                    break;
                case "completed":
                    alert("upload completed");
                    break;
                case "error":
                    this.emit("error", message);
                    break;
                default:
                    break;
            }
        });
    }

    fetchFile(file, agentId) {
        console.log('fetchFile', agentId);
        this.currentPage = 1;
        this.#loading = true;
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            const url = `${process.env.REACT_APP_WSS}/api/upload`;
            const uri = new URL(url);
            if (agentId) {
                uri.searchParams.append('agentId', agentId);
            }
            fetch(uri.href, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}` // Replace yourAuthToken with your actual token
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then(data => {
                    const result = JSON.parse(data);
                    this.#requestId = result.id;
                    this.#loading = false;
                    resolve({ header: result.header });
                })
                .catch(error => {
                    this.#loading = false;
                    reject(new Error(error, 'Error uploading file'));
                });
        });
    }

    validate() {
        return this.#wss.callFunc("validate", { requestId: this.#requestId });
    }

    setHeaderMapping(headerMatch) {
        return this.#wss.callFunc("setheaderColumn", { requestId: this.#requestId, headerMatch });
    };

    continueProcessImport() {
        return this.#wss.callFunc("processSaveToDB", { requestId: this.#requestId });
    }

    async fetchDataPerPage() {
        const res = await this.#wss.callFunc("fetchDataPerBatch", { requestId: this.#requestId, page: this.currentPage });
        if (res) {
            this.currentPage = this.currentPage + 1;
            return res;
        }
        return;
    }

}
