class ClientWatcher {
    #wss;
    #transcriptionId;
    #inboxId;
    #interactionId;
    #lastInboxId;
    #lastInteractionId;
    #lastTranscriptionId;
    constructor(wss) {
        this.#wss = wss;
    }
    /**
     * @returns {import("./services/websocket").default}
     */
    get Instance() {
        return this.#wss;
    }
    get InboxId() {
        return this.#inboxId;
    }
    // async fetchInbox({ lastInboxId }) {
    //     console.log(this.#lastInboxId);

    //     try {
    //         return await this.Instance.callFunc("fetchInbox", { lastInboxId: lastInboxId });
    //     } catch (error) {
    //         throw new Error(error);
    //     }

    // }
    async fetchInbox({ last }) {
        try {
            return await this.Instance.callFunc("fetchInbox", { last });
        } catch (error) {
            throw new Error(error);
        }
    }
    async getInbox(inboxId = null, leadId = null) {
        try {
            return await this.Instance.callFunc("getInbox", { inboxId, leadId });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchInteraction({ inboxId, last }) {
        try {
            return await this.Instance.callFunc("fetchInteraction", { inboxId, last });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchTranscription(interactionId) {
        try {
            return await this.Instance.callFunc("fetchTranscription", { interactionId });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchLead(leadId) {
        try {
            return await this.Instance.callFunc("fetchLead", { leadId });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchAllLead({ last, limit = 30, status }) {
        try {
            return await this.Instance.callFunc("fetchAllLead", { last, limit, status });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchLeadHistory(leadId) {
        try {
            return await this.Instance.callFunc("fetchLeadHistory", { leadId });
        } catch (error) {
            throw new Error(error);
        }
    }
    setInboxId(inboxId) {
        this.#inboxId = inboxId;
    }
    setTranscriptionId(transcriptionId) {
        this.#transcriptionId = transcriptionId;
    }
    setInteractionId(interactionId) {
        this.#interactionId = interactionId;
    }

    async resetUnreadCount(inbox) {
        try {
            await this.Instance.callFunc("resetUnreadCount", inbox);
        } catch (error) {
            throw new Error(error);
        }
    }
    updateData(lead) {
        this.#wss.callFunc("updateData", lead);
    }

    // settings
    async fetchAudioFiles() {
        try {
            return await this.Instance.callFunc("fetchAudioFiles");
        } catch (error) {
            throw new Error(error);
        }

    }

    updateSelectedAudio(id) {
        this.#wss.callFunc("updateSelectedAudio", id);
    }

    deleteAudioFile(id) {
        this.#wss.callFunc("deleteAudioFile", id);
    }
}

export default ClientWatcher;