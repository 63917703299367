import React, { useEffect, useState } from "react";
import { FACILITY_STATUS } from "../common/const";
import moment from "moment";

const ChangeStatus = ({ handleStatusChange = () => { }, lead = {} }) => {
    const [selectedStatus, setSelectedStatus] = useState("");

    useEffect(() => {
        Object.values(FACILITY_STATUS).map((status) => {
            if (status.key === lead.status.type) {

                setSelectedStatus(status.value);
            }
        });
    }, [lead.status.type]);

    const handleChange = (event) => {
        setSelectedStatus(event.target.value);
        handleStatusChange({ id: lead.id, status: { type: event.target.value, date: moment().valueOf() } });
    };

    const dropdownStyles = {
        padding: "8px",
        fontSize: "14px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        width: "200px",
        cursor: "pointer",
    };

    const optionStyles = {
        padding: "8px",
    };

    return (
        <div className="phonediv">
            <div className="socialtop">
                <div className="phonetop_left" >
                    <h3 className="phoneheader_text" >
                        {"Change Status"}
                    </h3>
                </div>
            </div>
            <div className="phone_dialer">
                <select
                    style={dropdownStyles}
                    defaultValue={selectedStatus}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        Select Status
                    </option>
                    {Object.values(FACILITY_STATUS).map((status) => (
                        <option
                            key={status.key}
                            value={status.key}
                            style={optionStyles}
                        >
                            {status.value}
                        </option>
                    ))}
                </select>
            </div>
            <br />
        </div >
    );
};

export default ChangeStatus;
