import React from "react";
import { FACILITY_STATUS } from "../common/const";

const TabsStatusFilter = ({ filter, removeFilter }) => {
    const [currentTab, setCurrentTab] = React.useState("All");

    React.useEffect(() => {
        switch (currentTab) {
            case "All":
                removeFilter("status");
                break;
            case FACILITY_STATUS.OPEN.value:
                filter({ status: FACILITY_STATUS.OPEN.key });
                break;
            case FACILITY_STATUS.CALLED.value:
                filter({ status: FACILITY_STATUS.CALLED.key });
                break;
            case FACILITY_STATUS.CALLEDSENDINFO.value:
                filter({ status: FACILITY_STATUS.CALLEDSENDINFO.key });
                break;
            case FACILITY_STATUS.FOLLOWUP.value:
                filter({ status: FACILITY_STATUS.FOLLOWUP.key });
                break;
            case FACILITY_STATUS.COLDLEADS.value:
                filter({ status: FACILITY_STATUS.COLDLEADS.key });
                break;
            case FACILITY_STATUS.CLOSED.value:
                filter({ status: FACILITY_STATUS.CLOSED.key });
                break;
            default:
                break;
        }
    }, [currentTab]);

    return (
        <div className={"convo-tabsmenu leads w-tab-menu"}>
            <a className={`convo-tablink w-inline-block w-tab-link ${currentTab === "All" ? "w--current" : ""}`} onClick={() => setCurrentTab("All")}>
                <div>{"All"}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.OPEN.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.OPEN.value)}
            >
                <div>{FACILITY_STATUS.OPEN.value}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.CALLEDSENDINFO.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.CALLEDSENDINFO.value)}
            >
                <div>{FACILITY_STATUS.CALLEDSENDINFO.value}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.FOLLOWUP.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.FOLLOWUP.value)}
            >
                <div>{FACILITY_STATUS.FOLLOWUP.value}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.COLDLEADS.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.COLDLEADS.value)}
            >
                <div>{FACILITY_STATUS.COLDLEADS.value}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.CALLED.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.CALLED.value)}
            >
                <div>{FACILITY_STATUS.CALLED.value}</div>
            </a>
            <a
                className={`convo-tablink w-inline-block w-tab-link ${currentTab === FACILITY_STATUS.CLOSED.value ? "w--current" : ""}`}
                onClick={() => setCurrentTab(FACILITY_STATUS.CLOSED.value)}
            >
                <div>{FACILITY_STATUS.CLOSED.value}</div>
            </a>
        </div>
    );
};

export default TabsStatusFilter;
