export const STATUS = {
    DEFAULT: "default",
    INCOMING: "incoming",
    ONCALL: "oncall",
    ERROR: "error"
};

export const LEAD_STATUS = {
    SIGNED_UP: "signed_up",
    PROSPECT: "prospect",
    WAITING: "waiting",
};

export const customCalendar = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
};

export const LEADS_PROTECTIVE_FIELDS = [
    "updatedAt",
    "createdAt",
];

export const FACILITY_STATUS = {
    OPEN: {
        key: 1,
        value: "Open"
    },
    CALLED: {
        key: 2,
        value: "Called - Not Interested"
    },
    CALLEDSENDINFO: {
        key: 3,
        value: "Called - Send Info"
    },
    COLDLEADS: {
        key: 4,
        value: "Cold Leads"
    },
    FOLLOWUP: {
        key: 5,
        value: "Called - Need Follow-up"
    },
    CLOSED: {
        key: 6,
        value: "Closed"
    }
};