import { QueryClient, QueryClientProvider, useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import React from "react";
import Utilities from './common/Utilities';

const queryClient = new QueryClient();

function QueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
}

export class ReactiveQuery {
    constructor(subscribe, queryKey) {
        this.subscribe = subscribe;
        this.queryKey = queryKey;
    }

    find = (queryFn, enabled, options = {}) => {
        const queryClient = useQueryClient();
        const { data, isLoading, isFetching, refetch } = useQuery({
            queryKey: this.queryKey,
            queryFn,
            enabled,
            ...options
        });

        React.useEffect(() => {
            this.listen();
        }, [queryClient, enabled]);

        return { data, isLoading: isLoading || isFetching, refetch };
    };

    findInfinite = (queryFn, enabled = true, options = {}, reverse = false) => {
        const queryClient = useQueryClient();
        const {
            data,
            fetchNextPage,
            hasNextPage,
            isFetchingNextPage,
            isLoading,
            isFetching,
            refetch
        } = useInfiniteQuery({
            queryKey: this.queryKey,
            queryFn: async ({ pageParam = null }) => {
                const result = await queryFn({ last: pageParam });
                return {
                    data: result.data,
                    nextCursor: result.nextCursor
                };
            },
            enabled,
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            ...options
        });

        React.useEffect(() => {
            this.listenInfinitely();
        }, [queryClient, enabled]);

        let retval = (data ? data.pages.flatMap(page => page.data) : []);
        if (reverse) {
            retval = retval.reverse();
        }
        return {
            data: retval,
            fetchNextPage,
            hasNextPage,
            isFetchingNextPage,
            isLoading: isLoading || isFetching,
            refetch
        };
    };

    // listenInfinitely() {
    //     const handleWebSocketMessage = (data) => {
    //         const newInteraction = data.message;
    //         queryClient.setQueryData(this.queryKey, (oldData) => {
    //             if (!oldData) return oldData;
    //             // Create a new pages array
    //             const newPages = oldData.pages.map((page, pageIndex) => {
    //                 if (pageIndex !== 0) return page; // Only modify the first page
    //                 // Add the new interaction to the beginning of the first page
    //                 return {
    //                     ...page,
    //                     data: [newInteraction, ...page.data]
    //                 };
    //             });
    //             return {
    //                 ...oldData,
    //                 pages: newPages
    //             };
    //         });
    //     };

    //     this.subscribe(this.queryKey, handleWebSocketMessage);
    // }

    listenInfinitely() {
        const handleWebSocketMessage = (data) => {
            const update = data.message;
            queryClient.setQueryData(this.queryKey, (oldData) => {
                if (!oldData) return oldData;
                const newPages = oldData.pages.map(page => {
                    const newData = page.data.map(item => {
                        if (item.id === update.id) {
                            return {
                                ...item,
                                ...update
                            };
                        }
                        return item;
                    });

                    if (page === oldData.pages[0] && !newData.some(item => item.id === update.id)) {
                        return {
                            ...page,
                            data: [update, ...newData]
                        };
                    }
                    return {
                        ...page,
                        data: newData
                    };
                });
                return {
                    ...oldData,
                    pages: newPages
                };
            });
        };

        this.subscribe(this.queryKey, handleWebSocketMessage);
    }

    listen() {
        const handleWebSocketMessage = (data) => {
            const newData = data.message;
            queryClient.setQueryData(this.queryKey, (oldData) => {
                if (Array.isArray(oldData) && Array.isArray(newData)) {
                    const oldDataMap = new Map(oldData.map(item => [item.id, item]));
                    newData.forEach(newItem => oldDataMap.set(newItem.id, newItem));
                    return Array.from(oldDataMap.values());
                } else if (typeof oldData === 'object' && oldData !== null && typeof newData === 'object' && newData !== null) {
                    if (oldData.id === newData.id)
                        return Utilities.deepMerge(oldData, newData);
                    else
                        return oldData;
                } else {
                    return newData;
                }
            });
        };
        this.subscribe(this.queryKey, handleWebSocketMessage);
    }
}


export default QueryProvider;