import React from "react";
import * as _Builtin from "../devlink/_Builtin";
import * as _utils from "../devlink/utils";
import _styles from "../devlink/Dialer.module.css";

export const Dialer = ({ sendDigits }) => {
    const [currentNumber, setCurrentNumber] = React.useState('');
    const addDigit = (digit) => {
        setCurrentNumber(prevNumber => prevNumber + digit);
        if (typeof sendDigits === "function") {
            sendDigits(digit);
        }
    };
    const erase = () => {
        setCurrentNumber(prevNumber => prevNumber.slice(0, -1));
    };
    const dialerHandler = (number) => { };

    return (
        <div className={"phone_dialer"} tag="div"
            style={{ display: "flex" }}
        >
            <_Builtin.Block
                className={_utils.cx(_styles, "dialer_maincontainer")}
                tag="div"
            >
                <_Builtin.Block
                    className={_utils.cx(_styles, "dialer_fieldblock-container")}
                    tag="div"
                >
                    <_Builtin.Block
                        className={_utils.cx(_styles, "dialerfield_container")}
                        tag="div"
                    >
                        <_Builtin.FormTextInput
                            className={_utils.cx(_styles, "dialerfield")}
                            name="name-3"
                            maxLength={256}
                            data-name="Name 3"
                            placeholder="(505)-xxx-xxxxx"
                            disabled={false}
                            type="text"
                            defaultValue={currentNumber}
                            onChange={(e) => setCurrentNumber(e.target.value)}
                            required={false}
                            autoFocus={false}
                            id="name-3"
                        />
                    </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block
                    className={_utils.cx(_styles, "dialer_main")}
                    tag="div"
                >
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("1")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"1"}</_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("2")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"2"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"ABC"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("3")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"3"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"DEF"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("4")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"4"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"GHI"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("5")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"5"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"JKL"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("6")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"6"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"MNO"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("7")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"7"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"PQRS"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("8")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"8"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"TUV"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("9")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"9"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"WXYZ"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                            onClick={() => addDigit("*")}
                        >
                            <_Builtin.Block tag="div">{"*"}</_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("0")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"0"}</_Builtin.Block>
                            <_Builtin.Block
                                className={_utils.cx(_styles, "keypad_subtext")}
                                tag="div"
                            >
                                {"+"}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={() => addDigit("#")}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "keypad")}
                            tag="div"
                        >
                            <_Builtin.Block tag="div">{"#"}</_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                    />
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                    >
                        <_Builtin.Link
                            className={_utils.cx(_styles, "icon_call")}
                            data-w-id="d105f99f-87d8-00b1-ab20-2f34de1ff959"
                            button={false}
                            block="inline"
                            options={{
                                href: "#",
                            }}
                            onClick={() => dialerHandler(currentNumber)}
                        >
                            <_Builtin.Image
                                width="auto"
                                height="auto"
                                loading="lazy"
                                alt=""
                                src="images/654dfac59c99624500d8adf9_home_icon_03-white.svg"
                            />
                        </_Builtin.Link>
                    </_Builtin.Block>
                    <_Builtin.Block
                        className={_utils.cx(_styles, "keypadcontainer")}
                        tag="div"
                        onClick={erase}
                    >
                        <_Builtin.Block
                            className={_utils.cx(_styles, "icon_dialer")}
                            tag="div"
                        >
                            <_Builtin.Image
                                width="auto"
                                height="auto"
                                loading="lazy"
                                alt=""
                                src="images/66968578d5889c78730e31a1_dialer_03.svg"
                            />
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </div>
    );
};
