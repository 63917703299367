"use client";
import React, { useRef, useCallback, useEffect, useState } from "react";
import * as _Builtin from "./_Builtin";
import { Inboxitem } from "./Inboxitem";
import * as _utils from "./utils";
import _styles from "./Inboxcol1.module.css";
import { LoaderChat } from "../component/common/LoaderChat";

export function Inboxcol1({ as: _Component = _Builtin.Block, data = [], handleClickUserInbox = () => { }, currentLead = {},
  loadMoreInbox = () => { }, isFetchingInbox = false
}) {
  const scrollRef = useRef(null);
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      // Calculate the distance from bottom
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);

      // Check if we're within 20px of the bottom and not already loading
      const shouldLoadMore = distanceFromBottom < 20 && !isNearBottom && !isFetchingInbox;
      if (shouldLoadMore) {
        setIsNearBottom(true);
        loadMoreInbox();
      } else if (distanceFromBottom > 20) {
        setIsNearBottom(false);
      }
    }
  }, [loadMoreInbox, isNearBottom, isFetchingInbox]);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;

    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);

    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <_Component className={_utils.cx(_styles, "dashboard-col1")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "inbox-top")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "phonetop_left")}
          tag="div"
        >
          <_Builtin.Heading
            className={_utils.cx(_styles, "phoneheader_text")}
            tag="h3"
          >
            {"Inbox"}
          </_Builtin.Heading>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "phonetop_right")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66969177fb74722e775dde42_inbox_01.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31ba_Icon_search.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66969177ee1a6a8cbeae5e12_inbox_02.svg"
            />
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "inbox-contain-primary")} tag="div">
        {data.length !== 0 && data.map((item, index) => {
          if (item.primary)
            return (
              <Inboxitem key={index}
                currentLead={currentLead}
                item={item}
                handleClickUserInbox={handleClickUserInbox}
                {...item.baseInfo} />
            );
          return null;
        })}
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "inbox-contain")} tag="div" ref={scrollRef}>
        {data.length !== 0 && data.map((item, index) => {
          if (!item.primary)
            return (
              <Inboxitem key={index}
                currentLead={currentLead}
                item={item}
                handleClickUserInbox={handleClickUserInbox}
                {...item.baseInfo} />
            );
          return null;
        })}
        {isFetchingInbox && <LoaderChat />}
      </_Builtin.Block>
    </_Component>
  );
}
