import moment from "moment";
import { LEAD_STATUS } from "./const";

export const interactions = [
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Hello, I am interested in your product. Can you please provide me with more information?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Sure, I can help you with that. What specific information are you looking for?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "I am looking for the price and the features of the product.",
        direction: "inbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "The product is priced at $1000 and it has the following features...",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Hello, I am interested in your product. Can you please provide me with more information?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Sure, I can help you with that. What specific information are you looking for?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "I am looking for the price and the features of the product.",
        direction: "inbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "The product is priced at $1000 and it has the following features...",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Hello, I am interested in your product. Can you please provide me with more information?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Sure, I can help you with that. What specific information are you looking for?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "I am looking for the price and the features of the product.",
        direction: "inbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "The product is priced at $1000 and it has the following features...",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Hello, I am interested in your product. Can you please provide me with more information?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Sure, I can help you with that. What specific information are you looking for?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "I am looking for the price and the features of the product.",
        direction: "inbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "The product is priced at $1000 and it has the following features...",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Hello, I am interested in your product. Can you please provide me with more information?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Sure, I can help you with that. What specific information are you looking for?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "I am looking for the price and the features of the product.",
        direction: "inbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "The product is priced at $1000 and it has the following features...",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    },
    {
        id: 1,
        leadId: 2,
        inboxId: 2,
        type: "",
        channelId: "",
        content: "Hello?",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 2,
        leadId: 2,
        inboxId: 2,
        type: "",
        channelId: "",
        content: "Hi, What's up?",
        direction: "outbound",
        createdAt: moment().valueOf() + 240000,
        updatedAt: moment().valueOf() + 240000
    },
    {
        id: 1,
        leadId: 2,
        inboxId: 2,
        type: "",
        channelId: "",
        content: "About the product",
        direction: "outbound",
        createdAt: moment().valueOf() + 120000,
        updatedAt: moment().valueOf() + 120000
    },
    {
        id: 2,
        leadId: 2,
        inboxId: 2,
        type: "",
        channelId: "",
        content: "Can you demonstrate the product?",
        direction: "outbound",
        createdAt: moment().valueOf() + 60000,
        updatedAt: moment().valueOf() + 60000
    }
];

export const interactionToAdd = [
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Added message 1",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Added message 1",
        direction: "inbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    },
    {
        id: 1,
        leadId: 1,
        inboxId: 1,
        type: "",
        channelId: "",
        content: "Added message 1",
        direction: "outbound",
        createdAt: moment().valueOf() + 480000,
        updatedAt: moment().valueOf() + 480000
    }
];

export const transcripts = [
    {
        id: 1,
        interactionId: 1,
        transcript: [
            {
                message: "Hello, how can I help you?",
                direction: "inbound",
                timestamp: moment().valueOf() + 1000
            },
            {
                message: "Hello, I am interested in your product. Can you please provide me with more information?",
                direction: "outbound",
                timestamp: moment().valueOf() + 2000
            },
            {
                message: "Sure, I can help you with that. What specific information are you looking for?",
                direction: "inbound",
                timestamp: moment().valueOf() + 3000
            },
            {
                message: "I am looking for the price and the features of the product.",
                direction: "outbound",
                timestamp: moment().valueOf() + 4000
            },
            {
                message: "The product is priced at $1000 and it has the following features...",
                direction: "inbound",
                timestamp: moment().valueOf() + 5000
            }
        ],
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    },
    {
        id: 2,
        interactionId: 2,
        transcript: [
            {
                message: "Hello?",
                direction: "inbound",
                timestamp: moment().valueOf() + 1000
            },
            {
                message: "Hi, What's up?",
                direction: "outbound",
                timestamp: moment().valueOf() + 2000
            },
            {
                message: "About the product",
                direction: "inbound",
                timestamp: moment().valueOf() + 3000
            },
            {
                message: "Can you demonstrate the product?",
                direction: "outbound",
                timestamp: moment().valueOf() + 4000
            }
        ],
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    }
];

export const leadskey =
{
    facilityContactPerson: { isSelected: false, key: "name" }, //previous key: name
    email: { isSelected: false, key: "email" },
    street: { isSelected: false, key: "street" },
    city: { isSelected: false, key: "city" },
    state: { isSelected: false, key: "state" },
    country: { isSelected: false, key: "country" },
    zipcode: { isSelected: false, key: "zipcode" },
    facilityNumber: { isSelected: false, key: "phoneNumber" }, //previous key: phoneNumber
    facilityPhone: { isSelected: false, key: "facilityPhone" },
    interestedIn: { isSelected: false, key: "interestedIn" },
    facilityName: { isSelected: false, key: "organization" }, //previous key: organization
    position: { isSelected: false, key: "position" },
    status: { isSelected: false, key: "status" },
    county: { isSelected: false, key: "county" },
    facilityCapacity: { isSelected: false, key: "facilityCapacity" },
    facilityType: { isSelected: false, key: "facilityType" },
    licenseName: { isSelected: false, key: "licenseName" },
    licenseStatus: { isSelected: false, key: "licenseStatus" },
    licenseDate: { isSelected: false, key: "licenseDate" },
    dateClosed: { isSelected: false, key: "dateClosed" },
    lastName: { isSelected: false, key: "lastName" },
    firstName: { isSelected: false, key: "firstName" },
};


export const leadsData = [
    {
        id: 1,
        name: "Albert Flores",
        emails: [
            { email: "albertf@gmail.com", primary: true },
            { email: "albertgame@gmail.com", primary: false }
        ],
        avatar: "images/avatar_01.png",
        address: [
            {
                street: "Street 1",
                city: "Albuqurque, NM",
                state: "New Mexico",
                country: "USA",
                zip: "23222",
                primary: true
            }
        ],
        phoneNumbers: [
            {
                number: "+1 505-555-5555",
                primary: true
            }
        ],
        interestedIn: ["Editing", "Product Management"],
        organization: "Company A",
        position: "Manager",
        notes: ["This is a note"],
        status: [LEAD_STATUS.PROSPECT, LEAD_STATUS.WAITING],
        tags: ["Tag 1", "Tag 2"],
        meta: { age: 25, sample: "Hello", website: "www.companya.com" },
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    },
    {
        id: 2,
        name: "Michael Smith",
        emails: [{ email: "michaelm@yahoo.com", primary: true }],
        avatar: "images/crm-top-01.png",
        address: [
            {
                street: "Street 1",
                city: "Manila, PH",
                state: "",
                country: "Philippines",
                zip: "12345",
                primary: true
            }
        ],
        phoneNumbers: [
            {
                number: "+1 505-555-5555",
                primary: true
            }
        ],
        interestedIn: ["Jumping", "Running"],
        organization: "Fitness Gym",
        position: "Instructor",
        notes: ["This is a note"],
        status: [LEAD_STATUS.PROSPECT],
        tags: ["Tag 1", "Tag 2"],
        meta: { age: 25, sample: "Hello", website: "www.fitnessgym.com" },
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    }
];

export const inboxes = [
    {
        id: 1,
        leadId: 1,
        type: "",
        channelInfo: {
            channelId: "",
            channelNumber: "",
            channelEmail: "",
            lastInteractionId: ""
        },
        baseInfo: {
            name: "Albert Flores",
            email: "albertf@gmail.com",
            phoneNumber: "+1 505-555-5555",
            avatar: "images/avatar_01.png",
            address: "Albuqurque, NM"
        },
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    },
    {
        id: 2,
        leadId: 2,
        type: "",
        channelInfo: {
            channelId: "",
            channelNumber: "",
            channelEmail: "",
            lastInteractionId: ""
        },
        baseInfo: {
            name: "Micheal Smith",
            email: "michaelm@yahoo.com",
            phoneNumber: "+1 505-555-5555",
            avatar: "images/crm-top-01.png",
            address: "Manila, PH"
        },
        createdAt: moment().valueOf(),
        updatedAt: moment().valueOf()
    }
];

export const sampleImportData = [
    {
        id: 1,
        fullName: "Albert Flores",
        email: "alberflo@gmail.com",
        phoneNumber: "+1 505-555-5555",
        completeAddress: "Albuqurque, NM"
    },
    {
        id: 2,
        fullName: "Albert Flores",
        email: "alberflo@gmail.com",
        phoneNumber: "+1 505-555-5555",
        completeAddress: "Albuqurque, NM"
    },
    {
        id: 3,
        fullName: "Albert Flores",
        email: "alberflo@gmail.com",
        phoneNumber: "+1 505-555-5555",
        completeAddress: "Albuqurque, NM"
    }
];

export const history = [{
    inboxId: "669e767a2254f0f3815f83dc",
    direction: "inbound",
    type: "call",
    from: "Patrick Jenkins",
    to: "Admin",
    createdAt: moment().valueOf(),
    updatedAt: moment().valueOf()
}, {
    inboxId: "669e78cda9b3d85f43fe0c73",
    direction: "outbound",
    type: "call",
    from: "Admin",
    to: "Patrick Jenkins",
    createdAt: moment().valueOf(),
    updatedAt: moment().valueOf()
}];