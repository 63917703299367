"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./LeadTableheader.module.css";

export function LeadTableheader({
  as: _Component = _Builtin.Block,
  sort = () => { },
  name = "Name",
  organization = "Organization",
  website = "Website",
  address = "Address",
  city = "City",
  contact = "Contact",
  status = "Status",
}) {

  const [sortOrder, setSortOrder] = React.useState(true);

  return (
    <_Component className={_utils.cx(_styles, "tablehd_container")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-auto")}
        tag="div"
      >
        <_Builtin.FormCheckboxWrapper
          className={_utils.cx(_styles, "checkbox-field")}
        >
          <_Builtin.FormCheckboxInput
            className={_utils.cx(_styles, "checkbox")}
            type="checkbox"
            name="checkbox-5"
            data-name="Checkbox 5"
            required={false}
            checked={false}
            id="checkbox-5"
            form={{
              type: "checkbox-input",
              name: "Checkbox 5",
            }}
            inputType="custom"
            customClassName="w-checkbox-input--inputType-custom"
          />
          <_Builtin.FormInlineLabel
            className={_utils.cx(_styles, "checkbox-label")}
          >
            {"Checkbox"}
          </_Builtin.FormInlineLabel>
        </_Builtin.FormCheckboxWrapper>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Name
        </_Builtin.Block>
        <_Builtin.Block className={_utils.cx(_styles, "icon-sort")} tag="div"
          onClick={() => {
            setSortOrder(!sortOrder);
            const order = sortOrder ? "asc" : "desc";
            sort("facilityName", order);
          }}>
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src="https://cdn.prod.website-files.com/671a44870449e1e437deb039/671a7ebf80c59746d48adce5_icon-sort.svg"
          />
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Phone
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Number
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Type
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-6p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Status
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          County
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Country
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Website
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Zipcode
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Capacity
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Facility Contact Person
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Licensee Name
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          License Status
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          License Date
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Date Closed
        </_Builtin.Block>
      </_Builtin.Block>
      {/* <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {contact}
        </_Builtin.Block>
        <_Builtin.Block className={_utils.cx(_styles, "icon-sort")} tag="div">
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src="https://cdn.prod.website-files.com/671a44870449e1e437deb039/671a7ebf80c59746d48adce5_icon-sort.svg"
          />
        </_Builtin.Block>
      </_Builtin.Block> */}
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          Updated By
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-5p5")}
        tag="div"
      />
    </_Component>
  );
}
